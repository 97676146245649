import React from "react";

 
const Popup_age = () => {
  return (
    <div className="ion-padding">
    
<p>Skin Age is an estimate of your age based on the condition of the surface of your face.</p>
<p>This estimation can be used as an indicator for your facial skin aging.</p>
<p>This estimation may be influenced by many factors such as fatigue level, the use of skincare or cosmetics products.</p>
<p>Video images taken in poor lighting conditions (e.g., backlighting, overhead lighting) may distort the estimation of your facial skin age.</p>
        
      
    </div>
  );
};
 
export default Popup_age;