/* eslint-disable react/jsx-pascal-case */
import { IonContent, IonHeader, IonModal } from "@ionic/react";
import React from "react";
import Popup_DiabetesRisk from './Elements/Popup_DiabetesRisk'
import Popup_HypercholesterolemiaRisk from './Elements/Popup_HypercholesterolemiaRisk'
import Popup_HypertensionRisk from './Elements/Popup_HypertensionRisk'
import Popup_HypertriglyceridemiaRisk from './Elements/Popup_HypertriglyceridemiaRisk'
import Popup_absi from './Elements/Popup_absi'
import Popup_age from './Elements/Popup_age'
import Popup_bmi from './Elements/Popup_bmi'
import Popup_breathing from './Elements/Popup_breathing'
import Popup_cardiacWorkload from './Elements/Popup_cardiacWorkload'
import Popup_cvdRisk from './Elements/Popup_cvdRisk'
import Popup_diastolic from './Elements/Popup_diastolic'
import Popup_healthScore from './Elements/Popup_healthScore'
import Popup_heartAttackRisk from './Elements/Popup_heartAttackRisk'
import Popup_heartRateVariability from './Elements/Popup_heartRateVariability'
import Popup_irregularHeartBeats from './Elements/Popup_irregularHeartBeats'
import Popup_msi from './Elements/Popup_msi'
import Popup_ppm from './Elements/Popup_ppm'
import Popup_systolic from './Elements/Popup_systolic'
import Popup_waistToHeight from './Elements/Popup_waistToHeight'
import Popup_strokeRisk from './Elements/Popup_strokeRisk'
 
const PopupModals = () => {
  
    return (<><IonModal initialBreakpoint={.8} trigger="trigger-DiabetesRisk" breakpoints={[0, 0, 1]} swipeToClose={true}>
      <IonHeader className='popup-header'></IonHeader>
      <IonContent>
        <Popup_DiabetesRisk />
      </IonContent>
    </IonModal>

    <IonModal initialBreakpoint={.8} trigger="trigger-HypercholesterolemiaRisk" breakpoints={[0, 0, 1]} swipeToClose={true}>
        <IonHeader className='popup-header'></IonHeader>
        <IonContent>
          <Popup_HypercholesterolemiaRisk />
        </IonContent>
    </IonModal>

    <IonModal initialBreakpoint={.8} trigger="trigger-HypertensionRisk" breakpoints={[0, 0, 1]} swipeToClose={true}>
        <IonHeader className='popup-header'></IonHeader>
        <IonContent>
          <Popup_HypertensionRisk />
        </IonContent>
    </IonModal>

    <IonModal initialBreakpoint={.8} trigger="trigger-HypertriglyceridemiaRisk" breakpoints={[0, 0, 1]} swipeToClose={true}>
        <IonHeader className='popup-header'></IonHeader>
        <IonContent>
          <Popup_HypertriglyceridemiaRisk />
        </IonContent>
    </IonModal>

    <IonModal initialBreakpoint={.8} trigger="trigger-absi" breakpoints={[0, 0, 1]}>
        <IonHeader className='popup-header'></IonHeader>
        <IonContent>
          <Popup_absi />
        </IonContent>
    </IonModal>

    <IonModal initialBreakpoint={.8} trigger="trigger-age" breakpoints={[0, 0, 1]} swipeToClose={true}>
        <IonHeader className='popup-header'></IonHeader>
        <IonContent>
          <Popup_age />
        </IonContent>
    </IonModal>

    <IonModal initialBreakpoint={.8} trigger="trigger-bmi" breakpoints={[0, 0, 1]} swipeToClose={true}>
        <IonHeader className='popup-header'></IonHeader>
        <IonContent>
          <Popup_bmi />
        </IonContent>
    </IonModal>

    <IonModal initialBreakpoint={.8} trigger="trigger-breathing" breakpoints={[0, 0, 1]} swipeToClose={true}>
        <IonHeader className='popup-header'></IonHeader>
        <IonContent>
          <Popup_breathing />
        </IonContent>
    </IonModal>

    <IonModal initialBreakpoint={.8} trigger="trigger-cardiacWorkload" breakpoints={[0, 0, 1]} swipeToClose={true}>
        <IonHeader className='popup-header'></IonHeader>
        <IonContent>
          <Popup_cardiacWorkload />
        </IonContent>
    </IonModal>

    <IonModal initialBreakpoint={.8} trigger="trigger-cvdRisk" breakpoints={[0, 0, 1]} swipeToClose={true}>
        <IonHeader className='popup-header'></IonHeader>
        <IonContent>
          <Popup_cvdRisk />
        </IonContent>
    </IonModal>

    <IonModal initialBreakpoint={.8} trigger="trigger-diastolic" breakpoints={[0, 0, 1]} swipeToClose={true}>
        <IonHeader className='popup-header'></IonHeader>
        <IonContent>
          <Popup_diastolic />
        </IonContent>
    </IonModal>

    <IonModal initialBreakpoint={.8} trigger="trigger-healthScore" breakpoints={[0, 0, 1]} swipeToClose={true}>
        <IonHeader className='popup-header'></IonHeader>
        <IonContent>
          <Popup_healthScore />
        </IonContent>
    </IonModal>

    <IonModal initialBreakpoint={.8} trigger="trigger-heartAttackRisk" breakpoints={[0, 0, 1]} swipeToClose={true}>
        <IonHeader className='popup-header'></IonHeader>
        <IonContent>
          <Popup_heartAttackRisk />
        </IonContent>
    </IonModal>

    <IonModal initialBreakpoint={.8} trigger="trigger-heartRateVariability" breakpoints={[0, 0, 1]} swipeToClose={true}>
        <IonHeader className='popup-header'></IonHeader>
        <IonContent>
          <Popup_heartRateVariability />
        </IonContent>
    </IonModal>

    <IonModal initialBreakpoint={.8} trigger="trigger-irregularHeartBeats" breakpoints={[0, 0, 1]} swipeToClose={true}>
        <IonHeader className='popup-header'></IonHeader>
        <IonContent>
          <Popup_irregularHeartBeats />
        </IonContent>
    </IonModal>

    <IonModal initialBreakpoint={.8} trigger="trigger-msi" breakpoints={[0, 0, 1]} swipeToClose={true}>
        <IonHeader className='popup-header'></IonHeader>
        <IonContent>
          <Popup_msi />
        </IonContent>
    </IonModal>

    <IonModal initialBreakpoint={.8} trigger="trigger-ppm" breakpoints={[0, 0, 1]} swipeToClose={true}>
        <IonHeader className='popup-header'></IonHeader>
        <IonContent>
          <Popup_ppm />
        </IonContent>
    </IonModal>

    <IonModal initialBreakpoint={.8} trigger="trigger-strokeRisk" breakpoints={[0, 0, 1]} swipeToClose={true}>
        <IonHeader className='popup-header'></IonHeader>
        <IonContent>
          <Popup_strokeRisk />
        </IonContent>
    </IonModal>

    <IonModal initialBreakpoint={.8} trigger="trigger-systolic" breakpoints={[0, 0, 1]} swipeToClose={true}>
        <IonHeader className='popup-header'></IonHeader>
        <IonContent><Popup_systolic /></IonContent>
    </IonModal>

    <IonModal initialBreakpoint={.8} trigger="trigger-waistToHeight" breakpoints={[0, 0, 1]} swipeToClose={true}>
        <IonHeader className='popup-header'></IonHeader>
        <IonContent>
          <Popup_waistToHeight />
        </IonContent>
    </IonModal></>);
  
};
 
export default PopupModals;

