import React from "react";

const Popup_terms = () => {
  return (

    <> <div className="ion-padding">
        <div className="et_pb_text_inner"><p><span>Last Updated: March 31, 2022</span></p>
        <p><span>Effective: These Terms of Use are effective as of the date of your first use of the Services.</span></p>
        <p><span>Triada HRM. (“</span><span className="strong">Triada</span><span>”, “</span><span className="strong">we</span><span>”, “</span><span className="strong">us</span><span>” or “</span><span className="strong">our</span><span>” and terms of similar meaning provides the “Triad HRM” application (“</span><span className="strong">Triada HRM</span><span>”), and its website located at app.triadahrm.com (“</span><span className="strong">Site</span><span>”), to help you measure, track and record your personal health and wellness status including, but not limited to heart rate and stress. We refer to the services provided by or through TriadaHRM App and the Site as the “</span><span className="strong">Services</span><span>”.</span></p>
        <p><span>These Terms of Use, together with the Triada HRM Privacy Policy (collectively, the “</span><span className="strong">Terms</span><span>”, as may be amended from time to time), govern your use of TriadaHRM App, the Site, and the Services.</span></p>
        <p><span className="strong">General<br /></span><span>Please read these Terms carefully before using the Services. By accessing or using the Services, you agree that you have read, understand and agree to be legally bound by these Terms. You state that you possess all the necessary legal authority to accept these Terms on behalf of yourself. If you do not agree to be bound by these Terms in their entirety, you may not use the Services.</span></p>
        <p><span className="strong">Change and Modifications</span><span><br /></span><span>We reserve the right to, at our sole discretion, to modify, discontinue or terminate the Site, Services or TriadaHRM App or to modify these Terms, at any time and without prior notice. Unless otherwise specified, any modifications to these Terms will be effective immediately upon being posted, and your continued use of the Services following any such modifications will be deemed acceptance thereof. You should from time to time review the Terms and any policies and documents incorporated in them to understand the terms and conditions that apply to your use of the Services. The Terms will always show the ‘last updated’ date at the top.</span></p>
        <p><span>If you do not agree to the modified Terms, you must stop using the Services. You can cancel your account with us without further obligation, except for any amounts that may be due for the balance of the billing period in which you cancel your account (if your billing period is monthly, we will prorate your account to the nearest month-end after cancellation).</span></p>
        <p><span className="strong">Privacy Policy<br /></span><span>For information regarding our collection and use of personally identifiable information, please carefully read our Privacy Policy which is incorporated into these Terms by this reference. By accessing or using the Services, you agree that we can collect and use your personally identifiable information in accordance with the Privacy Policy.</span></p>
        <p><span className="strong">Eligibility</span><span><br /></span><span>Our Services are not intended to be used by minors. You must be at least eighteen (18) years old to use the Services. If we determine that you are under the age of eighteen (18), we will terminate your use of the Services.</span></p>
        <p><span>If you intend to use the Services on behalf of a company, partnership, association, government or any entity or organization (“&nbsp;<span className="strong">Organization</span>”), then such use may not be undertaken pursuant to these Terms, but must rather be undertaken pursuant to a Services Agreement agreed to by the Organization.</span></p>
        <p><span className="strong">Accounts</span><span><br /></span><span>In order to access the Services, you are required to create a personal account by providing, accurate, true, current and complete information about yourself as prompted by the relevant registration processes (the “&nbsp;<span className="strong">Registration Data</span>”). You must promptly update your Registration Data as necessary to ensure it remains accurate, true, current and complete.</span></p>
        <p><span>If you provide any Registration Data that is untrue, inaccurate, not current or incomplete, or Triada HRM has reasonable grounds to believe that such Registration Data is untrue, inaccurate, not current or incomplete, Triada HRM has the right to suspend or terminate your access to or use of the Services.</span></p>
        <p><span>Any account that you open with us is personal to you and you are prohibited from gifting, lending, transferring or otherwise permitting any other person to access or use your account.</span></p>
        <p><span>You may be asked to create or you may receive a password and username designation upon completing the registration process. Such passwords or usernames will be for the exclusive and non-transferable use by you. You are responsible for safeguarding the confidentiality of the password and you are fully responsible for all activities that occur under your password or account. We are not liable for any loss or damages caused by your failure to maintain the confidentiality of your account credentials.</span></p>
        <p><span>You agree that you will not use another person’s and entity’s information including username, password or other account information. You also agree to notify us promptly of any unauthorized use of your username, password, other account information, or any other breach of security that you become aware of involving or relating to Services.</span></p>
        <p><span>If there is an indication that you are in breach of these Terms, or that your account has been compromised, Triada HRM may suspend, restrict or remove your access to the Services immediately without prior notice. Triada HRM may also remove your account or restrict your access to the Services, if you have not accessed or utilized the Services for more than six (6) months.</span></p>
        <p><span>Further, as a condition of using the Services, you must:</span></p>
        <ul className="c9 lst-kix_pnfrmyj06ol0-0 start">
        <li><span>not attempt to undermine the security or integrity of Triada HRM’s computing systems or networks or, where TriadaHRM App, the Site or Services are hosted by a third party, that third party’s computing systems and networks;</span></li>
        <li><span>not use, or misuse, TriadaHRM App, the Site or Services in any way which may impair their functionality, or the functionality of other systems used to deliver the Services or impair the ability of any other user to use TriadaHRM App, the Site or Services;</span></li>
        <li><span>not attempt to gain unauthorized access to any materials other than those to which you have been given express permission to access or to the computer system on which TriadaHRM App, the Site or Services are hosted;</span></li>
        <li><span>not use TriadaHRM App, the Site or Services to transmit any files that may damage any other person’s computing devices or software, content that may be offensive, or material or data in violation of any law (including data or other material protected by copyright or trade secrets which you do not have the right to use); and</span></li>
        <li><span>not attempt to modify, copy, adapt, reproduce, disassemble, decompile or reverse engineer any computer programs used to deliver the Services except as is strictly necessary to use either of them for normal operation.</span></li>
        </ul>
        <p><span className="strong">Our Content and Intellectual Property</span><span><br /></span><span>In these Terms the content and materials, including without limitation software, information, data, logos, marks, designs, images, text, graphics, buttons, domain names, meta-tags and the general ‘look and feel’, contained in or available through the Services (including, without limitation, TriadaHRM App and the Site) is called “<span className="strong">Content</span>”.</span></p>
        <p><span>Other than your User Content (defined below), all Content and all software available on or in respect of the Services or used to create and operate the Services (including, without limitation, TriadaHRM App and the Site), and any future updates, upgrades and new versions thereof is the property of Triada HRM or its licensors, and is protected by Canadian and international intellectual property laws, and all rights to the Services, such Content and such software are expressly reserved. All trademarks, registered trademarks, product names and company names or logos mentioned in the Services are the property of their respective owners. Triada HRM reserves all rights in and to the Content, the Services, TriadaHRM App and the Site not expressly granted to you under these Terms. Reference to any products, services, processes or other information, by trade name, trademark, manufacturer, supplier, or otherwise does not constitute or imply endorsement, sponsorship or recommendation thereof by Triada HRM.</span></p>
        <p><span>Triada HRM grants you a limited, revocable, non-exclusive, non-transferable, non-sublicensable license to access and use the Services and to view, copy and print the portions of the Content available to you through the Services solely for your own personal, non-commercial purposes. Such license includes the right to download, install and run a copy of TriadaHRM App on a mobile device that you own or control. Such license is subject to these Terms, and specifically conditioned upon the following (i) you may only view, copy and print such portions of the Content for your own use; (ii) you may not modify or otherwise make derivative works of the Content, or reproduce, distribute or display the Content (except for page caching) except as expressly permitted in these Terms; (iii) you may not remove or modify any copyright, trademark, or other proprietary notices that have been placed in the Content; (iv) you may not use any data mining, robots or similar data gathering or extraction methods; and (v) you may not use the Services or the Content other than for their intended purpose.</span></p>
        <p><span>Except as expressly permitted above, any access to or use of any portion of the Services or Content (including, without limitation, TriadaHRM App and the Site) is strictly prohibited and will terminate the licenses granted in this Section, these Terms and your account with us. Any such unauthorized use may also violate applicable laws, including without limitation copyright and trademark laws. Unless explicitly stated herein, nothing in these Terms may be construed as conferring any license to intellectual property rights, whether by estoppel, implication or otherwise. The licenses in this Section are revocable by Triada HRM at any time.</span></p>
        <p><span>You represent and warrant that your use of the Services and the Content will be consistent with this license and will not infringe or violate the rights of any other party or breach any contract or legal duty to any other parties, or violate any applicable law.</span></p>
        <p><span className="strong">User Content</span><span><br /></span><span>During your access or use of the Services, you are enabled or otherwise required to post, upload, store, share, send, or display your personally identifiable information and other data (such as your height, weight, age, gender, blood flow, etc.), profiles, opinions, messages, notes, comments, and any other information and content (“<span className="strong">User Content</span>”) to and via the Services.</span></p>
        <p><span>Your User Content is your property. Our only right to your User Content is the limited license to it granted in these Terms.</span></p>
        <p><span>Your User Content is your responsibility. We have no responsibility or liability for it, or for any loss or damage your User Content may cause to you or other people. Although we have no obligation to do so, we have the sole discretion to remove, screen or edit without notice any User Content posted or stored on the Services, and we may do this at any time and for any reason. You are solely responsible for maintaining copies of and replacing any User Content you post or store on the Services. If you authorize third parties to access your User Content through the Services, you agree that we are permitted to provide to them the User Content, and also agree that we have no responsibility or liability for their use of such User Content.</span></p>
        <p><span>By making your User Content available on or through the Services you hereby grant to Triada HRM a permanent, non-exclusive, transferable, sublicensable, worldwide, royalty-free, fully paid-up license to use, copy, modify, publicly display, publicly perform, reproduce, distribute, adapt, translate, create derivative works from, and distribute your User Content, in whole or in part, in any media in connection with operating, providing, developing and improving the Services and Content to you, including new services that we may provide in the future. All such use will, to the extent your User Content contains personally identifiable information, be in accordance with our Privacy Policy.</span></p>
        <p><span>You represent and warrant that you own and control all of the rights to your User Content, or you otherwise have the lawful right to use your User Content, in connection with the Services, and that such use does not violate these Terms and will not violate any rights of or cause injury to any person or entity.</span></p>
        <p><span>You represent and warrant that the use and provision of your User Content on the Service will not (i) infringe, misappropriate, or violate a third party’s rights; (ii) violate any applicable law or regulation or would give rise to civil liability; or (iii) be fraudulent, false, misleading, or deceptive. Triada HRM assumes no liability for your User Content (including without limitation maintaining a backup of your User Content) and you are solely responsible for any liability arising from or related to any of your User Content.</span></p>
        <p><span className="strong">Use of Your Device</span><span><br /></span><span>Full use of and access to the Services will be dependent on at least (among others) the following elements: (i) supported device with TriadaHRM App properly installed; (ii) adequate and legally compliant internet access; and (iii) processing and analysis capabilities located at remote servers. The availability and function of such elements may influence the performance of the Services. You are responsible for ensuring the mobile device has the adequate functions.</span></p>
        <p><span className="strong">Information Security</span></p>
        <p><span>We take reliability and security seriously, and put a great deal of effort into ensuring that our service operates all the time, and that it is a secure environment for your data. We use what we believe to be “best-of-className” hosting services and security technologies and services that we believe provide you with a secure and safe environment.</span></p>
        <p><span>However, no system is perfectly secure or reliable, the Internet is an inherently insecure medium, and the reliability of hosting services, Internet intermediaries, your Internet service provider, and other service providers cannot be assured. When you use the Services, you accept these risks, and the responsibility for choosing to use a technology that does not provide perfect security or reliability.</span></p>
        <p><span className="strong">Payment</span><span><br /></span><span>Any of the Services may be or may become subject to payment of fees. If any fees are charged, these will be notified separately and we may set out further terms applying to such payments.</span></p>
        <p><span className="strong">Links</span><span><br /></span><span>The Services may prompt or contain links to websites, applications or other products or services operated by other service providers (“<span className="strong">Third Party Apps</span>”). We do not endorse, monitor or guarantee the quality, reliability or suitability of any such Third Party Apps, which are subject to separate terms of use and privacy policies between you and the service providers that provide the Third Party Apps. We are not responsible or liable for any matters, losses or damages relating to your downloading, use or transactions with such Third Party Apps that may be accessible via the Services.</span></p>
        <p><span>The Services may also prompt or contain third party advertisements. All such advertisings are paid for by the relevant third party advertiser and are not recommendations or endorsements by the Company or its affiliates. You should refer to the relevant advertiser for information on its products and/or services. We have responsibility or liability for in respect of such third party advertisements. Any use of and reliance on such information is at your own risk.</span></p>
        <p><span className="strong">Feedback Policy</span><span><br /></span><span>Should you submit any comments or feedback to us, you agree that we can use, reproduce, and exploit them without any restriction and compensation to you, unless otherwise agreed between you and Triada HRM.</span></p>
        <p><span className="strong">Notifications</span><span><br /></span><span>You may receive notifications, emails, text messages, alerts, and other electronic communications during your use of the Services for the purpose as we may need to provide you with certain notifications, such as announcement regarding service changes or policy changes. We are not responsible for any fees which may be charged by your carrier regarding these communications. Any such communications will be in accordance with applicable law requirements.</span></p>
        <p><span className="strong">Additional Terms</span><span><br /></span><span>You acknowledge and agree that these Terms are not between you and the application stores where TriadaHRM App is downloaded from. You, and not Triada HRM, are solely responsible for any disputes or claims in relation to the application stores that are not connected with TriadaHRM App or your use of TriadaHRM App.</span></p>
        <p><span className="strong">DISCLAIMERS</span></p>
        <p><span className="strong">Not for Medical Purposes</span><span><br /></span><span className="strong">OUR SERVICES ARE NOT INTENDED FOR MEDICAL PURPOSES.</span><span>THE SERVICES DO NOT OFFER ANY KIND OF MEDICAL ADVICE AND ARE NOT INTENDED FOR ANY MEDICAL USE OR PURPOSES. OUR SERVICES ARE INTENDED FOR GENERAL WELLNESS ONLY.</span></p>
        <p><span>THE ESTIMATIVE MEASUREMENTS (“<span className="strong">MEASUREMENTS</span>”) PROVIDED TO YOU OF PHYSIOLOGICAL AND PSYCHOLOGICAL STATES INCLUDING HEART RATE, STRESS AND BLOOD PRESSURE SHOULD NOT BE TREATED AS MEDICAL ADVICE. THE MEASUREMENTS WE PROVIDE TO YOU ARE NOT INTENDED TO BE UTILIZED FOR ANY MEDICAL PURPOSES OR TO DIAGNOSE, TREAT, CURE OR PREVENT ANY DISEASE, AILMENT OR INJURY. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, YOU EXPRESSLY AGREE WE ARE NOT PROVIDING ANY MEDICAL ADVICE VIA OUR SERVICES. ALL CONTENT PROVIDED BY OUR SERVICES INCLUDING THE MEASUREMENTS IS NOT INTENDED TO BE AND SHOULD NOT BE USED IN PLACE OF (A) THE ADVICE OF YOUR PHYSICIAN OR OTHER MEDICAL PROFESSIONALS, AND (B) A VISIT, CALL OR CONSULTATION WITH YOUR PHYSICIAN OR OTHER MEDICAL PROFESSIONALS. SHOULD YOU HAVE ANY HEALTH-RELATED QUESTIONS, PLEASE CALL OR SEE YOUR PHYSICIAN OR OTHER HEALTHCARE PROVIDER PROMPTLY. IF YOU HAVE AN EMERGENCY, CALL YOUR PHYSICIAN OR YOUR LOCAL EMERGENCY SERVICES IMMEDIATELY. YOUR USE OF OUR SERVICES DOES NOT CONSTITUTE OR CREATE A DOCTOR-PATIENT, THERAPIST-PATIENT OR OTHER HEALTHCARE PROFESSIONAL RELATIONSHIP BETWEEN Triada HRM AND YOU.</span></p>
        <p><span className="strong">Accuracy</span><span><br /></span><span>THE MEASUREMENTS PROVIDED TO YOU ARE BASED ON INDICATORS AND STANDARDS DEVELOPED BY Triada HRM ITSELF AND ARE ONLY FOR GENERAL WELLNESS PURPOSES, WITHOUT ANY REPRESENTATIONS OR WARRANTIES, EXPRESS OR IMPLIED. WHILE WE MAKE EVERY EFFORT TO PROVIDE ACCURATE ESTIMATIVE MEASURES, WE MAKE NO WARRANTY AND DISCLAIM ALL RESPONSIBILITY AND LIABILITY FOR ANY ACCURACY, RELIABILITY, AVAILABILITY, SECURITY, COMPLETENESS OR CORRECT USE OF MEASUREMENTS, CONTENT AND ANY OTHER INFORMATION OR RESULTS YOU RECEIVE THROUGH THE SERVICES.</span></p>
        <p><span className="strong">Disclaimer of warranties and damages</span></p>
        <p><span>YOUR USE OF THE SERVICES, SOFTWARE (INCLUDING WITHOUT LIMITATION, TriadaHRM App AND THE SITE), AND CONTENT (COLLECTIVELY, THE “&nbsp;<span className="strong">Triada HRM SOLUTION</span>”), AND ANY THIRD PARTY APPS IS ENTIRELY AT YOUR OWN RISK, AND EXCEPT AS DESCRIBED IN THESE TERMS, ARE PROVIDED “AS IS.” TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, Triada HRM, ITS SUBSIDIARIES AND AFFILIATES, AND THEIR THIRD PARTY PROVIDERS, LICENSORS, DISTRIBUTORS OR SUPPLIERS (COLLECTIVELY “SUPPLIERS”) DISCLAIM ALL WARRANTIES AND CONDITIONS, EXPRESS OR IMPLIED, INCLUDING ANY WARRANTY OR CONDITION THAT THE Triada HRM SOLUTION OR THIRD PARTY APPS ARE OR WILL (i) BE FIT FOR A PARTICULAR PURPOSE, (ii) BE OF GOOD TITLE, (iii) BE OF MERCHANTABLE QUALITY, (iv) MEET YOUR REQUIREMENTS, (v) BE COMPATIBLE WITH YOUR DEVICE, OR (vi) NOT INTERFERE WITH OR INFRINGE OR MISAPPROPRIATE ANY INTELLECTUAL PROPERTY RIGHTS. FURTHERMORE, Triada HRM, ITS SUBSIDIARIES AND ITS AFFILIATES AND SUPPLIERS DISCLAIM ALL WARRANTIES AND CONDITIONS, EXPRESS OR IMPLIED AS TO THE ACCURACY, RELIABILITY, QUALITY OF CONTENT IN OR LINKED TO THE Triada HRM SOLUTION. Triada HRM, ITS SUBSIDIARIES AND ITS AFFILIATES AND SUPPLIERS DO NOT WARRANT THAT THE Triada HRM SOLUTION IS OR WILL BE SECURE, FREE FROM BUGS, VIRUSES, INTERRUPTION, DATA LOSS, ERRORS, THEFT OR DESTRUCTION.</span><span>&nbsp;</span></p>
        <p><span>SOME PROVINCES DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES OR CONDITIONS, SO THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU. THIS WARRANTY GIVES YOU SPECIFIC LEGAL RIGHTS, AND YOU MAY HAVE OTHER RIGHTS THAT VARY FROM PROVINCE TO PROVINCE. WE HAVE NO RESPONSIBILITY OR LIABILITY FOR ANY IMPROPER USE OR MISUSE OF THE SERVICES (SUCH AS ANY UNAUTHORIZED MEASUREMENT OF ANY OTHER PEOPLE WITHOUT EXPLICIT CONSENT OF THE LATTER), OR USE OF THE SERVICES FOR ANY UNINTENDED PURPOSE).</span></p>
        <p><span>WE HAVE NO RESPONSIBILITY OR LIABILITY FOR ANY IMPROPER USE OR MISUSE OF THE SERVICES (SUCH AS ANY UNAUTHORIZED MEASUREMENT OF ANY OTHER PEOPLE WITHOUT EXPLICIT CONSENT OF THE LATTER), OR USE OF THE SERVICES FOR ANY UNINTENDED PURPOSE).</span></p>
        <p><span className="strong">Termination</span><span><br /></span><span>These Terms will apply to your use of the Services until yourThese Terms will apply to your use of the Services until your access or use is terminated by either you or us. You may terminate your use of the Services at any time.</span></p>
        <p><span>It is our right to suspend or deactivate your account or ban your access to all or certain aspects of the Services, or to terminate these Terms, at any time and without notice or liability to you, at our sole discretion. We reserve the right to delete or remove any of the data or information related to your account, except that we may only retain and use your User Content in accordance with these Terms and, to the extent your User Content includes personally identifiable information, the Privacy Policy and applicable laws and regulations.</span></p>
        <p><span>If these Terms expire or terminate for any reason, provisions relating to privacy, confidentiality, ownership and protection of intellectual property, information security, indemnities and limitations of liability, governing law and dispute resolution, effects of termination, and any rights and obligations under these Terms which by their nature should survive, will remain in effect after termination or expiration of these Terms.</span></p>
        <p><span className="strong">Limitation of Liability; Indemnity</span><span><br /></span><span>You waive and shall not assert any claims or allegations of any nature whatsoever against Triada HRM, its affiliates or subsidiaries, their Suppliers, any of their successors or assigns, or any of their respective officers, directors, agents or employees (collectively, the “<span className="strong">Released Parties</span>”) arising out of or in any way relating to your use of the Triada HRM Solution or any Third Party Apps, including, without limitation, any claims or allegations relating to infringement of proprietary rights, or allegations that any Released Party has or should indemnify, defend or hold harmless you or any third party from any claim or allegation arising from your use or other exploitation of the Triada HRM Solution or any Third Party Apps. You use the Triada HRM Solution or any Third Party Apps at your own risk.</span></p>
        <p><span>Without limiting the foregoing, neither Triada HRM nor any other Released Party shall be liable for any direct, special, indirect, incidental, exemplary, punitive, or consequential damages, or any other damages of any kind, including but not limited to loss of use, loss of profits or loss of data, whether in an action in contract, tort (including but not limited to negligence) or otherwise, arising out of or in any way connected with the use of or inability to use the Triada HRM Solution or any Third Party Apps, including without limitation any damages caused by or resulting from your reliance on the Triada HRM Solution or any Third Party Apps or other information obtained from Triada HRM or any other Released Party or accessible via the Triada HRM Solution or any Third Party Apps, or that result from mistakes, errors, omissions, interruptions, deletion of files or email, defects, viruses, delays in operation or transmission or any failure of performance, whether or not resulting from acts of god, communications failure, theft, destruction or unauthorized access to Triada HRM or any other Released Party’s records, programs or services.</span></p>
        <p><span>In no event shall the aggregate liability of Triada HRM, whether in contract, warranty, tort (including negligence, whether active, passive or imputed), product liability, strict liability or other theory, arising out of or relating to the use of the Triada HRM Solution exceed any compensation paid by you for access to or use of the Triada HRM Solution during the three months prior to the date of any claim. In no event shall Triada HRM have or incur any liability, whether in contract, warranty, tort (including negligence, whether active, passive, or imputed), product liability, strict liability or other theory, arising out of or relating to the use any Third Party Apps.</span></p>
        <p><span>You will indemnify and hold harmless Triada HRM and any Released Parties from and against any claims, lawsuits, demands, disputes or investigations brought by any third party, and all liabilities, damages, losses, costs, and expenses, including without limitation reasonable attorneys’ fees, arising out of or in any way connected with: (i) your access to or use or misuse of the Services, (ii) your User Content, (iii) your breach or alleged breach of any warranties made by you hereunder or your violation of any other provision of these Terms, or (iv) your violation of any law or the rights of a third-party (collectively, “&nbsp;<span className="strong">Indemnified Claims</span>”). Triada HRM reserves the right, in its sole discretion and at its own expense, to assume the exclusive defense and control of any Indemnified Claims. You agree to reasonably cooperate as requested by Triada HRM in the defense of any Indemnified Claims.</span></p>
        <p><span className="strong">Governing Law and Dispute Resolutions</span><span><br /></span><span>The Services are controlled by Triada HRM and operated by it from its offices in Toronto, Ontario. You and Triada HRM both benefit from establishing a predictable legal environment in regard to the Services. Therefore, you and Triada HRM explicitly agree that all disputes, claims or other matters arising from or relating to your use of the Triada HRM Solution (or any component thereof) will be governed by the laws of the Province of Ontario and the federal laws of Canada applicable therein. The United Nations Convention on Contracts for the International Sale of Goods does not apply to these Terms.</span></p>
        <p><span>Except where prohibited by applicable law, any claim, dispute or controversy (whether in contract or tort, pursuant to statute or regulation, or otherwise, and whether preexisting, present or future) involving Triada HRM and arising out of or relating to (i) these Terms; (ii) the Triada HRM Solution (or any component thereof); (iii) oral or written statements, advertisements or promotions relating to these Terms or to the Triada HRM Solution (or any component thereof); or (d) the relationships that result from these Terms or the Triada HRM Solution (or any component thereof) (collectively, a “<span className="strong">Claim</span>”), will be referred to and determined by a sole arbitrator (to the exclusion of the courts). If you have a Claim, you should give written notice to arbitrate at the address specified below. If we have a Claim, we will give you notice to arbitrate at your address provided in your Registration Data. Arbitration will be conducted by one arbitrator pursuant to the commercial arbitration laws and rules in effect on the date of the notice in the Province of Ontario.</span></p>
        <p><span>To the extent arbitration as described in the immediately preceding paragraph is prohibited by applicable law, you agree that all Claims will be heard and resolved in a court of competent subject matter jurisdiction located in Toronto, Ontario. You consent to the personal jurisdiction of such courts over you, stipulate to the fairness and convenience of proceeding in such courts, and covenant not to assert any objection to proceeding in such courts.</span></p>
        <p><span>If you choose to access the Services from locations other than Ontario, you will be responsible for compliance with all local laws of such other jurisdiction and you agree to indemnify Triada HRM and the other Released Parties for your failure to comply with any such laws.</span></p>
        <p><span className="strong">General Terms</span><span><br /></span><span>Except as otherwise stated herein, the Privacy Policy and these Terms shall constitute the entire agreement and understanding between you and Triada HRM for the use of the Triada HRM Solution and supersede all prior or contemporaneous communications whether electronic, oral or written between you and Triada HRM regarding your use thereof. You agree that you will have no claim against us for any statement which is not explicitly set out in these Terms. If for any reason, a provision of these Terms is determined to be invalid or void, and unenforceable, it shall be excluded, and the other provisions remains in full force and effect. Triada HRM’s failure or delay to enforce any right or provision of these Terms will not be considered a waiver of those rights. Triada HRM may assign any or all of its rights hereunder to any party without your consent. The parties confirm that it is their wish that these Terms, as well as any other documents relating to this Terms, including notices, have been and shall be drawn up in the English language only. Les parties reconnaissent avoir convenue que la présente convention ainsi que tous documents, avis et procédures judiciaires qui pourront être exécutés, donnés ou intentées à la suite des présentes ou ayant un rapport, direct ou indirect, avec la présente convention soient rédigée en anglais.</span></p>
        <p><span className="strong">Contact Us</span><br /><span>The Services are operated and provided by Triada HRM. If you have any questions about these Terms or the Services, please email us at contact@triada.com</span></p></div>
        </div>
    </>
  );
};
 
export default Popup_terms;