import React from "react";
 
const Popup_strokeRisk = () => {
  return (
    <div className="ion-padding">
    
    <p>Stroke Risk is your likelihood of experiencing your first stroke within the next 10 years, expressed as a percentage.</p>
    <p>This risk score considers your gender, age, Body Mass Index, systolic blood pressure and antihypertensive medication status, as well as your smoking status and diabetes status (males only) when available. It was developed using the Framingham method. It is based on data from prospective studies that followed participants' cardiovascular health for over 10 years.</p>
    
        
      
    </div>
  );
};
 
export default Popup_strokeRisk;