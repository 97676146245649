import React from "react";
 
const Popup_healthScore = () => {
  return (
    <div className="ion-padding">
    
        
        
      
    </div>
  );
};
 
export default Popup_healthScore;