import { IonButton, IonCard, IonCheckbox, IonContent, IonImg, IonInput, IonItem, IonLabel, IonPage, IonSegment, IonSegmentButton, IonText } from '@ionic/react';
import './Profile.css';
import React from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { publicKey } from './publicKey';
import crypto from 'crypto';
import { Buffer } from 'buffer';
import PopupModalTerm from '../components/PopupModals/PopupTermsControllers';
import { useCookies } from 'react-cookie';





const Home: React.FC = () => {


  //FIXME learn how to not use this many use states
  const [name, setName] = React.useState<string>();
  const [age, setAge] = React.useState<number>();
  const [weight, setWeight] = React.useState<number>();
  const [heightIn, setHeightIn] = React.useState<number>();
  const [heightFt, setHeightFt] = React.useState<number>();
  const [smoker, setSmoke] = React.useState<string>();
  const [hypertension, setHypertension] = React.useState<string>();
  const [medication, setBloodPressure] = React.useState<string>();
  const [diabetic, setDiabetic] = React.useState<string>();
  const [gender, setGender] = React.useState<string>();
  const [checked, setChecked] = React.useState(false);
  const [cookies, setCookie] = useCookies();
  
  
  
  // this is what handles when the from is submited
  const handleSubmit = async (e: { preventDefault: () => void; }) => {

    
    e.preventDefault()
    //condtional divs
    var empty_message = document.getElementById('empty_fields');
    var submitting_message = document.getElementById('submit-message');
    var terms_message = document.getElementById('terms-message');

    if(empty_message)
        empty_message.style.display = 'none';

    //this if checks to see if any fields were left blank, if they were this shoots out red error message, renders the div
    if(gender == null || smoker == null || hypertension  == null || medication  == null || diabetic == null){
        if(empty_message)
        empty_message.style.display = 'block'; 
        return;
    }

    if(!checked){

     

      if(terms_message)
      terms_message.style.display = 'block'; 
        return;
    }
    
    //makes sure empty message disapears
      if(empty_message)
      empty_message.style.display = 'none'; 

      if(terms_message)
      terms_message.style.display = 'none'; 

    //renders submitting... message
      if(submitting_message)
      submitting_message.style.display = 'block';

    
    // conversions from imerial to metric
    //FIXME look into having user select on form, mectric or imerial
    const weightKg = (weight! / 2.2046).toFixed(0);
    const heightCm = ((heightIn! * 2.54) + (heightFt! * 12 * 2.54)).toFixed(0);


    //Calls to backend in heroku get congig ID and auth token
    const { data: configResponseData } = await axios.get('https://hrm-app-example.herokuapp.com/api/configId');
    const { data: tokenResponseData } = await axios.post('https://hrm-app-example.herokuapp.com/api/token');

    const payload = {
      identifier: uuidv4(), // Unique identifier for the user, it will be used to tag the measurement
      age,
      height: parseInt(heightCm),
      weight: parseInt(weightKg),
      gender,
      smoking: smoker === 'yes' ? '1' : '0',
      antihypertensive: hypertension === 'yes' ? '1' : '1',
      bloodpressuremedication: medication === 'yes' ? '1' : '0',
      diabetes: diabetic,
    };

    const buffer = Buffer.from(JSON.stringify(payload));
 
    const encryptedProfile = crypto.publicEncrypt(publicKey, buffer);
 
    const sessionId = uuidv4(); // Unique identifier for the session, passed back as part of the result object to identify the measurement
    setCookie('name', name, { path: '/results' });
    setCookie('age', age, { path: '/results' });
    setCookie('gender', gender, { path: '/results' });
    setCookie('weight', weightKg, { path: '/results' });
    setCookie('height', heightCm, { path: '/results' });
    setCookie('smoker', smoker, { path: '/results' });
    setCookie('hypertension', hypertension, { path: '/results' });
    setCookie('medication', medication, { path: '/results' });
    setCookie('diabetic', diabetic, { path: '/results' });

    window.location.href = `https://awe.na-east.nuralogix.ai/c/${
      configResponseData.configId
    }/${encodeURIComponent(encryptedProfile.toString('base64'))}/${encodeURIComponent(
      tokenResponseData.Token
    )}/${encodeURIComponent(
      tokenResponseData.RefreshToken
    )}/${sessionId}`;

    console.log(tokenResponseData)
    
    
  }
 

  
  
  
  return (
  <IonPage>
    
        
    <IonContent fullscreen>
      <div className = "container" >
        
        
        <IonCard className="form-card">
        <IonImg src="/assets/img/allied-logo.png" className="main-logo"></IonImg>
        <IonText color="primary">
          <h1>
              Face Scan
          </h1>
        </IonText>

          
    
        <form className='form-card' onSubmit={handleSubmit}>

        <IonItem>
          <IonLabel position="stacked">Name</IonLabel>
          <IonInput required name="name" onIonChange={ e => setName(e.detail.value!)}></IonInput>
       </IonItem> 

        <IonItem>
          <IonLabel position="stacked">Age</IonLabel>
          <IonInput required name="age" onIonChange={ e => setAge(parseInt(e.detail.value!, 10))}></IonInput>
       </IonItem>

       <IonItem>
          <IonLabel position="stacked">Weight (lb)</IonLabel>
          <IonInput required name="weight" onIonChange={e => setWeight(parseInt(e.detail.value!, 10))}></IonInput>
       </IonItem>
       

       <IonItem >
          <IonLabel position="stacked">Heigth (ft in)</IonLabel>

           
         
             <IonInput placeholder="feet" required name="heightFt" onIonChange={e => setHeightFt(parseInt(e.detail.value!, 10))}></IonInput>
             <IonInput placeholder="inches" required name="heightIn" onIonChange={e => setHeightIn(parseInt(e.detail.value!, 10))}></IonInput>
            

        </IonItem>
        

      
       <IonLabel className='ion-padding'>Gender at birth?*</IonLabel>
       <IonItem>
       <IonSegment onIonChange={e => setGender(e.detail.value!)}>
          <IonSegmentButton value="male">
            <IonLabel>Male</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="female">
            <IonLabel>Female</IonLabel>
          </IonSegmentButton>
        </IonSegment>
        </IonItem>

        <IonLabel className='ion-padding'>Do you smoke?*</IonLabel>
       <IonItem>
       <IonSegment onIonChange={e => setSmoke(e.detail.value!)}>
          <IonSegmentButton value="yes">
            <IonLabel>Yes</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="no">
            <IonLabel>No</IonLabel>
          </IonSegmentButton>
        </IonSegment>
        </IonItem>

        <IonLabel className='ion-padding'>Do you have hypertension?*</IonLabel>
       <IonItem>
       <IonSegment onIonChange={e => setHypertension(e.detail.value!)}>
          <IonSegmentButton value="yes">
            <IonLabel>Yes</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="no">
            <IonLabel>No</IonLabel>
          </IonSegmentButton>
        </IonSegment>
        </IonItem>

        <IonLabel className='ion-padding'>Are you taking any blood<br></br> pressure medication?*</IonLabel>
       <IonItem>
       <IonSegment onIonChange={e => setBloodPressure(e.detail.value!)}>
          <IonSegmentButton value="yes">
            <IonLabel>Yes</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="no">
            <IonLabel>No</IonLabel>
          </IonSegmentButton>
        </IonSegment>
        </IonItem>

        <IonLabel className='ion-padding'>Are you diabetic?*</IonLabel>
       <IonItem>
       <IonSegment onIonChange={e => setDiabetic(e.detail.value!)}>
          <IonSegmentButton value="type1">
            <IonLabel>Type 1</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="type2">
            <IonLabel>Type 2</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="no">
            <IonLabel>No</IonLabel>
          </IonSegmentButton>
        </IonSegment>
        </IonItem>

        <IonItem>
              <IonLabel>I agree to the <a className='terms' href="https://alliedwellness.com/app-terms/" target="_blank">terms of service</a></IonLabel>
              <IonCheckbox slot="start" onIonChange={e => setChecked(e.detail.checked)}/>
        </IonItem>

        
          

          <div className="empty-message" id="empty_fields">
            <IonLabel>Please make sure all fields are selected</IonLabel>
          </div>

          <div className="empty-message" id="terms-message">
            <IonLabel>Please accepts Terms</IonLabel>
          </div>

          <div className="submit-message" id="submit-message">
            <IonLabel>Submitting...</IonLabel>
          </div>
          
          <IonButton className="ion-margin" type='submit'>Continue</IonButton>
      
      </form>
          
        </IonCard>
      </div>

      <PopupModalTerm/>


    </IonContent>
  </IonPage>
  );
};




export default Home;