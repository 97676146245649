import React from "react";
 
const Popup_heartRateVariability = () => {
  return (
    <div className="ion-padding">
    
      <p>Heart Rate Variability refers to the variability in the timing between one heartbeat and the next. The scan measures this with SDNN, which is the standard deviation of the interval between normal heartbeats (in milliseconds). A higher SDNN corresponds to more inter-beat variability.</p>
      <p>Increased heart rate variability suggests an increase in parasympathetic activity and/or a decrease in sympathetic activity of the autonomic nervous system.</p>
      <p>High resting heart rate variability is considered healthy and means that the heart is more flexible to the changing needs of the body.</p>
      
        
      
    </div>
  );
};
 
export default Popup_heartRateVariability;