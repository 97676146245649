export const publicKey = `
-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA0S4W6EUT87CyWAL6zlep
GCWo2YRYDDEuJvtlTBnU62wvDXMNJp4gSAVOjpjnOre80dv3XP1U5npG2I3juIbT
uXBs4+J1tn7HuHOy6wSLjxKO23/jX/DZwY9ZKnKjPboEFgCT3o3Hdy5lQ5StnXyG
8VTfxiNQuDN2OI2XxUP/n7gYCkebEZ/J2PI602rrpytpJK4jJpO2RNGB+Qw2Ly1D
O4YtdVQj3vTjdpERN6JjsWbnvL6MBzRyNBU0rXiaqLUEY5akaJvhSeIfCzDo0jPE
EgMa2biqY1FpLVmJJLMH0r3LvYjoMHDOGirxNazaTu2tQLyedFX1G3gxQNKoMkQB
zQIDAQAB
-----END PUBLIC KEY-----
`;