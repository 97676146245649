import React from "react";
 
const Popup_ppm = () => {
  return (
    <div className="ion-padding">
    
       
        <p>A range of 60 to 100 beats per minute (bpm) is considered a normal range for an adult's resting heart rate.</p>
        <p>Resting heart rate can be influenced by many factors, including, but not limited to, the following:</p>
        <ul>
          <li>Fitness level</li>
          <li>Age</li>
          <li>Cardiovascular disease</li>
          <li>Smoking</li>
          <li>Medications</li>
          <li>Body position (e.g. standing, sitting or lying down)</li>
        </ul>

        <p>In general, a lower resting heart rate is indicative of more efficient heart function and better cardiovascular fitness. Athletes can have a normal resting heart rate below 60 bpm with some having resting heart rates closer to 40 bpm.</p>
        <p>If you are concerned about your resting heart rate or have further questions, please consult with a medical professional.</p>
        <p>For additional information on resting heart rate, see Mayo Clinic's "<a href="https://www.mayoclinic.org/healthy-lifestyle/fitness/expert-answers/heart-rate/faq-20057979#:~:text=A%20normal%20resting%20heart%20rate%20for%20adults%20ranges%20from%2060,to%2040%20beats%20per%20minute.">What's a normal resting heart rate?</a>" or the American Heart Association's "<a href="https://www.heart.org/en/healthy-living/fitness/fitness-basics/target-heart-rates" >Know Your Target Heart Rates for Exercise, Losing Weight and Health</a>" and "<a href="https://www.heart.org/en/health-topics/high-blood-pressure/the-facts-about-high-blood-pressure/all-about-heart-rate-pulse">All About Heart Rate (Pulse)</a>".</p>
    </div>
  );
};
 
export default Popup_ppm;
