/* eslint-disable react/jsx-pascal-case */
import { IonContent, IonHeader, IonModal } from "@ionic/react";
import React from "react";
import Popup_Terms from './PopupTermsOfService'

 
const PopupModalTerms = () => {
  
    return (<>
    
    <IonModal initialBreakpoint={.8} trigger="trigger-terms" breakpoints={[0, 0, 1]} swipeToClose={true}>
      <IonHeader className='popup-header'></IonHeader>
      <IonContent>
        <Popup_Terms />
      </IonContent>
    </IonModal>

    </>
    );
  
};
 
export default PopupModalTerms;