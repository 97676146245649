import React from "react";

 
const Popup_DiabetesRisk = () => {
  return (
    <div className="ion-padding">
    
      <p>Type 2 diabetes risk corresponds to the percentage of people with the user's risk profile who are diagnosed with type 2 diabetes (impaired processing of blood sugar) by their doctor.</p>
      <p>This risk profile is based on their facial blood flow and demographic information. It does not necessarily reflect the user's current blood sugar level.</p>
      <p>Uncontrolled type 2 diabetes can cause problems like vascular disease, heart disease, kidney damage, and nerve damage. Therefore, those with a high type 2 diabetes risk should consider getting screened for type 2 diabetes by their doctor.</p>
      <p><strong>Notice</strong>: Moment-to-moment fluctuations in your physiology mean that your risk estimates could vary to some degree from one measurement to the next. The best estimate of your overall risk is therefore obtained by averaging several measurements throughout the day and across several days to adequately account for this physiological variation.</p>
        
      
    </div>
  );
};
 
export default Popup_DiabetesRisk;