import React from "react";
const Popup_bmi = () => {
  return (
    <div className="ion-padding">
      
<p>Body Mass Index (BMI) is a measure of an individual’s tissue mass (muscle, fat, and bone) adjusted for height. It is a commonly used indicator of overall body fat and serves as a tool for categorizing individuals as being underweight, normal weight, overweight, or obese on the basis of health risk.</p>
<p>Body Mass Index is a fair predictor of cardiovascular diseases, type 2 diabetes, gallstones, and certain cancers. It is the one of the best anthropometric predictors of high-density lipoprotein (HDL) and triglyceride levels; these are important factors for cardio-metabolic diseases. It is also one of the best anthropometric predictors of uric acid levels (high uric acid levels contribute to gallstones). A limitation of Body Mass Index is that it cannot distinguish between fat and muscle mass and thus can be inaccurate in muscular individuals.</p>
<p>You can calculate your Body Mass Index using the formula:
BMI = weight (kg) / height (m²)</p>
<p>According to the <strong>World Health Organization</strong>:</p>
<table><thead><tr><th>BMI</th><th>Weight Status</th></tr></thead><tbody><tr><td>Below 18.5</td><td>Underweight</td></tr><tr><td>18.5-24.9</td><td>Normal weight</td></tr><tr><td>25.0-29.9</td><td>Pre-obesity</td></tr><tr><td>30.0-34.9</td><td>Obesity Class I</td></tr><tr><td>35.0-39.9</td><td>Obesity Class II</td></tr><tr><td>40 or above</td><td>Obesity Class III</td></tr></tbody></table>
<p>For more information, see: Zhou, W., Wang, Y., Gu, X., Feng, Z. P., Lee, K., Peng, Y., &amp; Barszczyk, A. (2020) Importance of general adiposity, visceral adiposity and vital signs in predicting blood biomarkers using machine learning. <em>International Journal of Clinical Practice</em>, e13664.</p>
        
      
    </div>
  );
};
 
export default Popup_bmi;